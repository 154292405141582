  .site-layout-sub-header-background {
    background: #fff;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }

  .site-layout-background {
    background: #fff;
    padding: 15px; /*added in date - to check*/
  }

  .sideStyle {
    height: '100vh';
    margin: 64px 0 0 0;
  }

  .menuStyle {
    position: fixed;
    width: 200px;
  }

  .ant-menu-inline-collapsed {
    width: 0;
  }

  .footerStyle {
    text-align: center;
    position: fixed;
    margin: 0 0 0 200px;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .ant-layout-sider-children {
    background: midnightblue;
  }

  .ant-menu .ant-menu-item {
    border-radius: 0px;
  }

  .ant-layout .ant-layout-footer {
    padding: 0px 10px;
  }

  .ant-layout .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    width: 0;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: midnightblue;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    color: midnightblue;
  }

  b {
    color: midnightblue;
  }

  p {
    flex: 1;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  h3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: auto;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
  }

  /*style={{ background: 'rgba(0, 0, 0, 0.02)', color: 'midnightblue', textAlign: 'center' }}*/

  .ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-content {
    height: 45px;
  }

  .ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-content {
    padding: 0 0 12px 0;
  }

  /* font-family: 'Courier New'*/

  .versionStyle{
    color: lightgray;
    position: fixed;
    bottom: 0;
    padding: 5px;
    margin: 0 0 0 5px;
  }

  .ant-picker-calendar .ant-picker-calendar-header{
    padding: 0 0 12px 0;
    margin-top: -12px
  }

  .ant-btn{
    height: 27px;
    padding: 0px 4px;
  }