.video-responsive {
  overflow: hidden;
  /*padding-bottom: 56.25%;*/
  position: relative;
  height: 200px;
  width: 350px;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}